@charset "utf-8";

// Bootstrap configuration
$enable-rounded: true;
$enable-gradients: true;

// Theme colors
$astronaut: #2c4d76; // A dark navy. Used as primary text color and dark background color.
$havelock: #4e8ad4; // A strong, punchy blue. Suitable for primary actions.
$mantis: #58b55c; // A strong, punchy green. Suitable for primary actions that feel "positive" or "completing".
$athens: #e1e6ea; // A soft, blue-tinged, light grey. Suitable for non-primary actions.
$porcelain: #f6f7f8; // A subtle off-white. Suitable for contrasting sections against white.

// Text emphasis colors
$shout-color: rgba($astronaut, 1);
$speak-color: rgba($astronaut, 0.8);
$whisper-color: rgba($astronaut, 0.6);
$deselected-color: rgba($astronaut, 0.33);

// Assign theme colors to bootstrap variables
$primary: $havelock;
$secondary: $athens;
$success: $mantis;
$light: $porcelain;
$dark: $astronaut;
$body-color: $speak-color;
$headings-color: $shout-color;
$link-color: $havelock;
$theme-colors: (
  "astronaut": $astronaut,
  "mantis": $mantis,
  "havelock": $havelock
);
$border-color: rgba(black, 0.1);
$table-accent-bg: $porcelain;

$box-shadow-sm: 0 0.125rem 0.25rem rgba(0, 0, 32, 0.1);
$box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 32, 0.1);
$box-shadow-lg: 0 0.75rem 1.5rem rgba(0, 0, 32, 0.1);

$navbar-light-color: $whisper-color;
$navbar-light-active-color: $shout-color;
$navbar-light-hover-color: $primary;

$navbar-dark-color: rgba(white, 0.5);
$navbar-dark-active-color: white;
$navbar-dark-hover-color: white;

$small-font-size: 0.75rem;

$badge-font-size: 0.875rem;
$badge-border-radius: 3px;

@font-face {
  font-family: "SFProRounded";
  src: url("./fonts/sf-pro-rounded-regular.woff2") format("woff2"),
    url("./fonts/sf-pro-rounded-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "SFProRounded";
  src: url("./fonts/sf-pro-rounded-semibold.woff2") format("woff2"),
    url("./fonts/sf-pro-rounded-semibold.woff") format("woff");
  font-weight: 700; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "SFMono";
  src: url("./fonts/sf-mono-regular.woff2") format("woff2"),
    url("./fonts/sf-mono-regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

$font-family-sans-serif: "SFProRounded", -apple-system, BlinkMacSystemFont,
  "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
  "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-monospace: "SFMono", SFMono-Regular, Menlo, Monaco, Consolas,
  "Liberation Mono", "Courier New", monospace !default;

@import "~bootstrap/scss/bootstrap";

.row-cols-xl-8 {
  @include media-breakpoint-up(xl) {
    @include row-cols(8);
  }
}

.text-shout {
  color: $shout-color;
}
.text-speak {
  color: $speak-color;
}
.text-whisper {
  color: $whisper-color;
}
.text-deselected {
  color: $deselected-color;
}

.inner-border {
  box-shadow: inset 0 0 0 1px $border-color;
}

h1,
.h1 {
  font-size: 2.5rem;
  @include media-breakpoint-down(sm) {
    font-size: 2rem;
  }
}

h2,
.h2 {
  font-size: 2rem;
  @include media-breakpoint-down(sm) {
    font-size: 1.75rem;
  }
}

h3,
.h3 {
  font-size: 1.75rem;
  @include media-breakpoint-down(sm) {
    font-size: 1.5rem;
  }
}

h4,
.h4 {
  font-size: 1.5rem;
  @include media-breakpoint-down(sm) {
    font-size: 1.25rem;
  }
}

h5,
.h5 {
  font-size: 1.25rem;
  @include media-breakpoint-down(sm) {
    font-size: 1.125rem;
  }
}

h6,
.h6 {
  font-size: 1rem;
  @include media-breakpoint-down(sm) {
    font-size: 1rem;
  }
}

.secondary-nav {
  font-size: 0.875rem;
  @media (min-width: 48rem) {
    font-size: 1rem;
  }

  background-color: $astronaut;
  margin-left: -(0.5 * $grid-gutter-width);
  margin-right: -(0.5 * $grid-gutter-width);

  .nav-item {
    margin-right: 1rem;
    @media (min-width: 48rem) {
      margin-right: 1.5rem;
    }

    .nav-link {
      color: $navbar-dark-color;
      padding-left: 0;
      padding-right: 0;
      padding-top: $spacer * 0.75;
      padding-bottom: $spacer / 2;

      @include hover-focus() {
        color: $navbar-dark-hover-color;
      }
    }

    .nav-link.active {
      color: $navbar-dark-active-color;
      border-bottom: 4px solid $mantis;
    }
  }
}

.secondary-nav.no-gutters {
  margin-left: 0;
  margin-right: 0;
}

.secondary-nav-light {
  @extend .secondary-nav;

  background-color: $light;

  .nav-item {
    .nav-link {
      color: $navbar-light-color;

      @include hover-focus() {
        color: $astronaut;
      }
    }

    .nav-link.active {
      color: $astronaut;
    }
  }
}

.clickable-card {
  transition: all 200ms ease-in-out;
  box-shadow: 0 0.125rem 0.25rem rgba($astronaut, 0.05);

  @include hover-focus() {
    border-color: $havelock;
    transform: translate(0px, -1px);
    box-shadow: 0 0.125rem 0.5rem rgba($havelock, 0.25);
  }
}

.overlap-item {
  position: relative;
  display: inline-block;

  .overlap-badge {
    font-size: 1rem;
    position: absolute;
    right: -0.5rem;
    top: -0.5rem;
    padding: 0.25rem 0.5rem;
    border: solid 2px white;
  }
}

.card-detail-page-section {
  background: white;
  border: 1px solid $border-color;
  border-radius: $border-radius;
  padding: $spacer;
}

.login-gated {
  background: repeating-linear-gradient(
    135deg,
    $white,
    $white 16px,
    rgba($light, 0.5) 16px,
    rgba($light, 0.5) 32px
  );
  padding: $spacer;
  border: 1px solid $border-color;
}

.max-width-26 {
  max-width: 26rem;
}

.max-width-30 {
  max-width: 30rem;
}

.max-width-40 {
  max-width: 40rem;
}

.pointer-cursor {
  cursor: pointer;
}

html,
body,
#root {
  height: 100vh;
}

.sidebar {
  width: 18rem;
  min-width: 18rem;
  max-width: 18rem;
}

.inner-container-narrow {
  min-width: 0;
  max-width: 60rem;
}
.inner-container {
  min-width: 0;
  max-width: 80rem;
}
.inner-container-wide {
  min-width: 0;
  max-width: 90rem;
}

.horizontal-scroll {
  overflow-x: auto;
}

.badge-row {
  .badge-row-item {
    text-align: center;
    margin-right: 0.5rem;

    &:last-child {
      margin-right: 0;
    }
  }
}
.badge-row-right {
  .badge-row-item {
    text-align: center;
    margin-left: 0.5rem;

    &:first-child {
      margin-left: 0;
    }
  }
}

.faq-section {
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
}

.cms-markdown {
  font-size: 1rem;
  line-height: 1.5rem;

  h1 {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    font-weight: bold;
    padding-bottom: 1rem;
    margin-bottom: 1.5rem;
  }
  h2,
  h3 {
    font-weight: bold;
    padding: 0;
    margin: 1.5rem 0;
  }
  p {
    line-height: 1.66rem;
    margin: 0.5rem 0 1rem;
    padding: 0;
  }
  ol,
  ul {
    margin: 0.5rem 1rem 1rem;
    padding: 0 1rem 0;
  }
  li {
    margin: 0.5rem 0;
    padding: 0 0 0 0.5rem;
  }
  li li {
    padding: 0;
  }
  li:last-child {
    padding-bottom: 0;
  }
  li > ol,
  li > ul {
    padding-bottom: 0;
  }
  strong {
    color: #2c4d76;
  }
  table {
    border: 1px solid rgba(0, 0, 0, 0.1);
    box-shadow: 0 0.25rem 0.5rem rgba(0, 0, 32, 0.1);
    width: 100%;

    th {
      background: #4e8ad4;
      color: #fff;
    }

    tr {
      td,
      th {
        border: 1px solid rgba(0, 0, 0, 0.1);
        padding: 0.75rem;
        vertical-align: top;
      }
    }
  }

  .footnote-backref {
    display: none;
  }
}

// Hacky fix for adding spacing between carousel items in slick
// https://www.cluemediator.com/add-space-between-carousel-items-in-react-slick
.slick-list {
  margin-top: -2px;
  margin-right: -16px;
  padding-top: 2px;

  .slick-track {
    display: flex;
    // Left align items in the track, rather than center
    margin-left: 0;
  }
}
.slick-slide {
  height: inherit;

  & > div {
    height: 100%;
    margin-right: 16px;
    overflow: visible;
  }

  & > div > div {
    // Slick applies an inline-block style to slides, causing extra height due to
    // font size. This negates that.
    display: block !important;
    height: 100%;
  }
}

.slick-dots {
  position: absolute;
  width: 100%;
  margin: 0;
  padding: 0;
  list-style: none;
  text-align: center;

  li {
    padding: 0;
    display: inline-block;
    margin-right: 8px;
    align-items: center;
    vertical-align: top;

    &:last-child {
      margin-right: 0;
    }
  }
}
