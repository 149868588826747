/* Increase font size */
.bb svg {
  font: 12px sans-serif; }

/* Chart text color (e.g. axis labels) */
.bb text,
.bb .bb-button {
  fill: #567191; } /* text-speak: #567191 @ 80% */

/* Lighten axis line color */
.bb path,
.bb line {
  stroke: #d0d0d0; }

/* Lighten axis tick color */
.bb .tick line {
  stroke: #e0e0e0; }

/* Lighten grid line color */
.bb-grid line {
  stroke: #e0e0e0; }

/* Increase line chart stroke width */
.bb-line {
  stroke-width: 2px; }

/* Customise chart dots to be white-centered with stroke */
.bb-circle {
  fill: white !important;
  stroke-width: 2px; }
  .bb-circle._expanded_ {
    stroke: white;
    stroke-width: 2px; }

/* Increase legend font size */
.bb-legend-item {
  font-size: 12px; }
